#imageButton {
  border: none;
  background-color: transparent;
  cursor: pointer;

  background-color: white;
  padding: 10px;
  border-radius: 50%;
}

#buttonImage {
  width: 40px;
  height: 40px;
  transition: width 0.3s, height 0.3s;
  transition: transform 0.5s;
}

.social-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  border-radius: 50%;
  background-color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  width: 60px;
  height: 60px;
  z-index: 5;
}

.social-button:hover {
  background-color: rgb(216, 214, 214);
}

// .button-text {
//   margin-right: 10px;
// }

.social-icons {
  position: absolute;
  bottom: 80px;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  opacity: 0;
  transform: translateY(20px);
  animation: slideIn 0.6s ease forwards;
}

.social-icons__link {
  background: white;
  display: block;
  padding: 8px;
  border-radius: 50%;
  transition: transform 0.3s !important;
}

.social-icons__link--disabled {
  pointer-events: none;
}

.social-icons__link:hover {
  transform: scale(1.2) !important;
}


@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.social-icons img {
  width: 40px;
  height: 40px;
}

.social-icons a {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.social-icons.show-icons a:nth-child(1) { transition-delay: 0.1s; }
.social-icons.show-icons a:nth-child(2) { transition-delay: 0.2s; }
.social-icons.show-icons a:nth-child(3) { transition-delay: 0.3s; }

.social-icons.show-icons a {
  opacity: 1;
  transform: translateY(0);
}

.scale-out {
  transform: scale(0.5);
}
