// @import "fonts";

// @import "typography";
@import "mixins";
@import "reset";
@import "header";
@import "our-approach";
@import "projects";
@import "info-block";
@import "button";

@import "icon";
@import "form";
@import "fonts";

@import "section";
@import "cooperation";
@import "design-prices";
@import "services-and-prices.scss";
@import "social-button";
@import "offer";
@import "save";
@import "difference";
@import "footer.scss";

/* #region page */
input,
textarea {
  transition: background-color 5000s ease-in-out;
  -webkit-text-fill-color: white;
}

.page {
  font-family: 'Inter', sans-serif;
  line-height: 140%;
  margin: 0 auto;

  background-color: #000000;
  word-spacing: 4px;

  box-sizing: border-box;
  scroll-behavior: smooth;
}

.page:has(.menu:target) {
  overflow: hidden; /* not to scroll the page */
}
.main {
  margin: 0 auto;
  max-width: 1440px;
}

a {
  text-decoration: none;
}


/* #endregion */


/* #region slider*/

.slider-container {
  //width: 720px;
  //margin: auto;
  // background: white;
  position: relative;
}

.slider-container .fade {
  animation-name: fade;
  animation-duration: 0.5s;

}

@keyframes fade {
  from {opacity: 0.4}
  to {opacity: 1}
}



.slider-container .slides .slider-numbers {
  position: absolute;

  width: 100%;
  line-height: 1.3;
  // text-align: center;
  color: #FFF;

  background-color: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(5px);

  .italic {
    font-size: 18px;
  }

  .slider-numbers-title {
    padding-top: 15px;
    text-align: center;
  }

  p {
    padding: 15px 0 15px 15px;

    @include onTablet {
      padding: 15px 0 15px 35px;;
    }

    @include onDesktop {
      padding: 15px 0 15px 10%;
    }
  }
}

.slider-container .slides .slider-numbers1 {
  position: absolute;
  // padding: 10px;
  width: 100%;
  top: 30%;
  text-align: center;
  font-size: 45px;
  line-height: 1.5;
  color: #FFF;

  font-weight: 700;
  text-shadow: 4px 4px 3px rgba(0, 0, 0, 1);
}

.slider-container .slides .slider-caption {
  text-align: center;
  font-size: 20px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  color: #FFF;
  padding: 15px 0;

  background-color: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(5px);
}

.slider-container .prev,
.slider-container .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #FFF;
  font-weight: bold;
  padding: 15px 25px;
  font-size: 30px;
  text-decoration: none;
}

.slider-container .next {
  right: 0px;
}

.slider-container .prev:hover{
  background: rgba(0, 0, 0, 0.6);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
}
.slider-container .next:hover {
  background: rgba(0, 0, 0, 0.6);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  cursor: pointer;
}

.slider-container .slider-bullets {
  text-align: center;
}

.slider-container .slider-bullets .dots {
  display: inline-block;
  padding: 5px;
  width: 10px;
  height: 10px;
  background-color: #808080;
  border-radius: 50%;
}

// .slider-image {
  // width: auto;
  // height: 600px;
  // display: flex;
  // align-items: center;
  // justify-self: center;
  // justify-content: center;


  // .cucu {
  //   max-width: 600px;
  //   max-height: 600px;
  //   width: 100%;
  //   height: 100%;
  //   background-image: url(/images/main1.jpg);

  //   background-size: cover; /* или contain */
  //   background-repeat: no-repeat;
  //   background-position: center;
  // }

  // div {
  //   width: fit-content;

  //   height: 600px;
  //   width: 600px;

    // img {
    //   display: flex;
    //    background-image: url(/images/main1.jpg);

    //   background-size: cover; /* или contain */
    //   background-repeat: no-repeat;
    //   background-position: center center;

    // }
  // }

// }

.slider-image img {
  width: 100%;
  height: 600px;
  display: flex;
  object-fit: contain;

  @include onMobile {
    height: 500px;
  }

}


/* #endregion */


// second slider

#slider-container {
  display: none; /* Приховати контейнер слайдера за замовчуванням */
}

.container {
  position: relative;
  max-width: 800px;
  height: auto;
  margin: auto;
  padding-top: 3vh;
  //border: #fff solid 3px;
  background: rgb(255, 255, 255);
  background: black;
  border-bottom: 2px solid #fff;
  overflow: auto;
  max-height: 600px;
  z-index: 6;
}

.sub-imgs, .main-img {
  border-inline: 2px solid #fff;
  border-top: 2px solid #fff;
}

.sub-imgs {
  border-inline: 2px solid #fff;
}

.main-img img,
.sub-imgs img {
  width: 100%;
}

.sub-imgs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}

/* Slide buttons */
.slide-buttons{
  position: absolute;
  left: 2%;
  top: 30%;
  transform: translate(-2%, -30%);
  width: 100%;
}

.slide-buttons button{
  width: 20px;
  height: 50px;
  //background: none;
  color: rgba(255, 255, 255, 0.452);
  color: #fff;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  font-size: 1.8rem;
  z-index: 1;
  background: #6b6b6b;
  opacity: 0.8;
  border-radius: 8px;
}

#left-button{
  margin-left: 2%;
}

#right-button{
  margin-left: 90%;
}


/* Fade in animation for the carousel */
@keyframes fadeIn {
  100%{
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.3s ease-in 1 forwards;
}

@media screen and (max-width: 1000px) {
  .container {
    max-width: 760px;
    margin-top: 2vh;
  }
}

@media screen and (max-width: 600px) {
  .line{
    width: 30px;
    height: 3px;
    background: black;
    margin: 5px;
  }

  nav {
    position: relative;
  }

  .hamburger {
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 50%;
    transform: translate(-5%, -50%);
  }

  .nav-links {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.548);
    height: 90vh;
    margin-top: 10vh;
    width: 100%;
    flex-direction: column;

    z-index: 1;
    clip-path: circle(100px at 85% -25%);
    -webkit-clip-path: circle(100px at 85% -25%);
    transition: all 0.5s ease-out;

    /* Non clickable when closed */
    pointer-events: none;
  }

  .nav-links.open {
    clip-path: circle(1000px at 85% -25%);
    -webkit-clip-path: circle(1000px at 85% -25%);
    z-index: 1;
    pointer-events: all;
  }

  .container {
    max-width: 500px;
    margin-top: 10vh;
  }

  .nav-links li{
    opacity: 0;
  }

  .nav-options{
    font-size: 2.0rem;
  }

  .nav-links li:nth-child(1){
    /* property, time, timing, delay */
    transition: all 0.5s ease-in 0.1s;
  }
  .nav-links li:nth-child(2){
    transition: all 0.5s ease-in 0.2s;
  }
  .nav-links li:nth-child(3){
    transition: all 0.5s ease-in 0.3s;
  }
  .nav-links li.fade{
    opacity: 1;
  }


  #left-button{
    margin-left: 4%;
  }

  #right-button{
    margin-left: 80%;
  }
}

.slider-button {
  position: absolute;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  height: 50px;
  width: 240px;
  top: 86%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0;
  background: transparent;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  /* background: gray; */
  opacity: 0.7;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s;

  background-color: rgba(255, 255, 255, 1);
  backdrop-filter: blur(5px);

  .button-text {
    color: #000000;
    z-index: 4;
  }
}

.slider-button:hover {
  background-color: gray;
}

// scrollbar styles
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
