@mixin onMobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin onTablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin onDesktop {
  @media (min-width: 1240px) {
    @content;
  }
}

@mixin onDesktopPlus {
  @media (min-width: 1390px) {
    @content;
  }
}
