.footer {
  padding-top: 35px;

  @include onTablet {
    padding-top: 50px;
  }

  .footer-container {
    margin: 0 auto;
    max-width: 1170px;
    display: flex;
    justify-content: space-around;

    @include onTablet {
      justify-content: space-between;
      padding: 0 34px;
    }

    @include onDesktop {
      padding: 0;
    }

    h2 {
      color: white;

      @include onTablet {
        padding-bottom: 20px;
      }

      @include onDesktop {
        padding-bottom: 30px;
      }
    }

    .contact-link, .about-link {
      transition: all 0.5s;
      text-decoration: none;
      color: white;
      max-width: 170px;

      &:hover {
        color: #887676;
        border-bottom: 1px solid #ffffff;;
      }
    }

    .adrress, .about {
      text-decoration: none;
      color: #000;
      max-width: 175px;

      @include onMobile {
        display: none;
      }
    }

    .social-network {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 265px;

      @include onTablet {
        width: 280px;
      }

      .social-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 35px;

        @include onTablet {
          gap: 52px;
        }

        @include onDesktop {
          gap: 65px;
        }
      }

      .title-box {
        justify-content: center;
        box-sizing: border-box;

        .copyring {
          font-size: 12px;
          font-weight: 100;
          color: white;

          @include onTablet {
            display: none;
          }
        }

        .title-container {
          width: 100%;
          text-align: center;
          border-top: 1px solid transparent;
          flex-direction: column;
          padding-top: 10px;

          @include onMobile {
            padding-bottom: 30px;
          }

          @include onTablet {
            padding-bottom: 80px;
          }

          @include onDesktop {
            padding-bottom: 65px;
          }

          .logo {
            @include onTablet {
              width: 85%;
            }
          }
        }

        .title {
          width: 100%;
        }
      }

      .footer-logo {
        position: relative;
        width: fit-content;
      }

      .footer-logo:before {
        content: '';
        display: block;
        position: absolute;
        top: -5px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #f1f1f1;
        transform: scaleX(0);
        transition: transform 0.5s ease;
      }

      .footer-logo:hover::before {
        transform: scaleX(1);
      }

      .link {
        display: flex;
        justify-content: center;
        width: 25px;
        height: 25px;
        transition: transform 0.5s;

        @include onTablet {
          width: 30px;
          height: 30px;
        }

        &:hover {
          transform: scale(150%);
        }
      }
    }
  }

  .copyring2 {
    color: white;
  }
}
