.our-approach {
  background-color: white;

  .button-container {
    justify-content: center;
    padding-bottom: 15px;
    height: 100%;
    align-items: center;

    @include onTablet {
      // padding-top: 20px;
      padding-bottom: 0;
      // align-items: flex-end;
    }
  }

  .container-our-approach-info {
    padding-bottom: 15px;

    @include onTablet {
      padding-bottom: 0;
    }
  }

  .info-tablet {
    padding-bottom: 20px;
  }

  .info {
    display: inline;

    @include onTablet {
      display: block;

      // font-size: 18px;
      text-align: justify;
    }
  }

  .content {
    display: inline;
    line-height: 1.1;

    @include onTablet {
      display: block;
      padding: 0 0 20px;
    }
  }
}

.our-approach-info {

  @include onTablet {
    display: flex;
    gap: 30px;
  }
}

.approach-container {
  @include onTablet {
    width: 65%;
  }
}

.our-approach-title {
  width: 100%;
  text-align: end;
}

.tablet-approach {
  display: none;

  @include onTablet {
    display:flex;
    flex-direction: column;
    width: 35%;
    justify-content: space-between;
  }
}

.our-approach-img {
  width: 100%;
  max-width: 100%;
  padding-bottom: 20px;

  @include onTablet {
    display: none;
  }
}

.info-block {
  @include onTablet {
    display: flex;
  }
}

.approach-icon {
  display: flex;
  justify-content: center;
}

.approach-icon-img {
  height: 50px;
  width: 50px;

  @include onTablet {
    display: none;
  }
}

.img {
  width: 100%;
  height: auto;
  display: block;
}

.tablet-img {
  display: none;

  @include onTablet {
    display: block;
    padding-bottom: 20px;
    width: 100%;
    height: auto;
  }
}

.none-tablet {
  @include onTablet {
    display: none;
  }
}

.bardack-photo {
  display: none;

  @include onTablet {
    display: block;
    width: 100%;
  }
}

.content {
  display: inline;

  @include onTablet {
    display: block;
    padding: 0 0 20px 0;
  }
}


