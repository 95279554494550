/* #region header */
.header {
  flex-direction: column;
  height: 100vh;
}

.header__top {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;

  height: 85%;

  @include onTablet {
    height: 83%;
  }

  .rSector {
    display: flex;
    justify-content: end;
    bottom: -19%;

    position: relative;
    height: 48px;

    .discount {
      display: flex;
      align-items: center;

      .discount-content {
        position: absolute;
        transform: skew(-35deg);
        background-color: rgba(0, 0, 0, 0.45);
        backdrop-filter: blur(5px);

        width: 285px;
        height: 100%;
        z-index: -1;
        right: -25px;

        @include onTablet {
          width: 305px;
        }

        @include onDesktop {
          width: 340px;
        }
      }

      .discount-box {
        padding-right: 20px;

        @include onTablet {
          padding-right: 39px;
        }

        @include onDesktop {
          padding-right: 75px;
        }

        .discount-title {
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 600;
          line-height: 1;
          color: white;
        }

        .discount-info {
          text-transform: lowercase;
          font-size: 12px;
          color: white;
        }
      }
    }
  }

  .lSector {
    display: flex;
    bottom: -15%;
    position: relative;

    @include onTablet {
      bottom: -5%;
    }

    @include onDesktop {
      bottom: -5%;
    }

    .h-info {
      display: flex;
      height: 48px;

      @include onTablet {
        height: 70px;
      }

      .h-info-content {
        background-color: rgba(255, 255, 255, 0.45);
        backdrop-filter: blur(5px);

        width: 352px;
        height: 100%;
        z-index: -1;
        left: -30px;
        position: absolute;

        transform: skew(-35deg);

        @include onTablet {
          left: -90px;
          width: 550px;
        }

        @include onDesktop {
          left: -50px;
        }
      }

      .h-info-box {
        display: flex;
        align-items: center;

        .h-info-box-title {
          padding-left: 15px;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 1;

          @include onTablet {
            padding-left: 35px;
          }

          @include onDesktop {
            padding-left: 70px;
          }
        }
      }
    }
  }

  /* Анимация для правого сектора */
  .animate-on-visible.from-right .discount {
    animation: slideRight 2s forwards;
  }

  .animate-on-visible.from-left .h-info {
    animation: slideLeft 2s forwards;
  }

  /* Ключевые кадры анимации */
  @keyframes slideLeft {
    0% {
      transform: translateX(-100%);
    }
    70% {
      transform: translateX(0);
    }
    85% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }
    70% {
      transform: translateX(0);
    }
    85% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
}

.header-container {
  background-color: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: space-between;
}

.header-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.header__bottom {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  height: 15%;

  @include onTablet {
    height: 17%;
  }
}

.title-container {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .ht {
    line-height: 1.25;
  }
}

.message {
  display: none;

  @include onTablet {
    font-size: 14px;
    // width: 220px;
    color: white;
    display: block;
    text-transform: uppercase;
  }

  @include onDesktop {
    font-size: 16px;
    margin-left: 20px;
    width: 450px;
  }
}

/* #endregion header */

/* #region menu */
.menu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  box-sizing: border-box;
  padding: 25px 20px;
  height: 100vh;
  width: 100%;
  background-color: #161412;
  z-index: 2;

  opacity: 0;
  pointer-events: none;

  font-weight: 700;
  text-transform: uppercase;
  overflow: auto;

  @include onTablet {
    width: 60%;
  }
  @include onDesktop {
    width: 40%;
  }
}

.menu:target {
  opacity: 1;
  pointer-events: all;
}

.menu-container {
  padding-top: 15px;
  padding-bottom: 55px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
/* #endregion */

/* #region dropbtn */
.dropdown {
  position: relative;
}

.dropbtn {
  background-image: url(/icons/phone-call2.png);
  background-size: cover;
  background-position: center center;
  height: 35px;
  width: 30px;
  cursor: pointer;
  transition: all 0.5s;
}

.dropbtn:hover {
  transform: scale(150%);
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -58px;
  bottom: -70px;

  overflow: hidden;
  transform-origin: center top;
  transform: scaleY(0);
  transition-delay: 200ms;
  gap: 3px;

  @include onTablet {
    bottom: -70px;
  }

  @include onDesktop {
    bottom: -75px;
  }
}

.dropdown:hover .dropdown-content {
  transform: scaleY(1);
}

.a-dropdown-container {
  display: flex;
  border-radius: 15px;
  background-color: #f1f1f1;
  width: 165px;
  height: 25px;

  @include onTablet {
    width: 185px;
  }
}

.a-dropdown-content {
  display: flex;
  align-items: center;
  width: 100%;

  color: black;

  transition: transform 0.5s ease;
  transform: scaleY(0);
  transition-delay: 200ms;
  transform-origin: center top;

}

.a-text {
  display: flex;

  justify-content: space-around;
  width: 100%;
}

.dropdown:hover .a-dropdown-content {
  transform: scaleY(1);
}
/* #endregion */

/* #region nav__list */
.nav__link {
  color: white;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 2px;
}

.nav__item {
  position: relative;
  width: fit-content;
}

.nav__item:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #f1f1f1;
  transform: scaleX(0);
  transition: transform 0.5s ease;
}

.nav__item:hover::after {
  transform: scaleX(1);
}

.nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include onTablet {
    gap: 30px;
  }

  @include onDesktop {
    gap: 25px;
  }
}
/* #endregion */

/* #region ... */
/* #endregion ...*/
