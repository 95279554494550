.section {
  padding: 0 15px;

  @include onTablet {
    padding: 0 35px;
  }

  @include onDesktop {
    max-width: 1440px;
    padding: 0 70px;
  }
}

.header-section {
  padding: 15px;

  @include onTablet {
    padding: 6px 35px;
  }

  @include onDesktop {
    max-width: 1440px;
    padding: 10px 70px;
  }
}

.title-container {
  display: flex;
  box-sizing: border-box;
  align-items: center;
}

.title-section {
  padding: 15px;

  @include onTablet {
    padding: 25px 35px;
  }

  @include onDesktop {
    // max-width: 1440px;
    padding: 30px 70px;
  }
}
