.cooperation {
  background-color: white;
}

.bold {
  font-weight: bold;
}

.cooperation-icon {
  display: flex;
  justify-content: center;
}

.cooperation-icon-img {
  height: 50px;
  width: 50px;
}

.cooperation-container {
  display: flex;

  @include onDesktop {
    gap: 30px;
  }
}

.cooperation-title {
  width: 100%;
  text-align: end;
}

.cooperation-list {
  padding-left: 25px;

  @include onTablet {
    padding-left: 45px;
  }

  @include onDesktop {
    display: none;
  }
}

.cooperation-list-onDesktop {
  display: none;

  @include onDesktop {
    display: block;
    padding-left: 45px;
  }
}

.cooperation-button {
  display: none;

  @include onTablet {
    padding-top: 15px;
    display: flex;
    justify-content: center;
  }
}

.nonTablet{
  @include onTablet {
  display: none;
  }

  @include onDesktop {
  display: flex;
  }
}

.nonDesktop{
  @include onDesktop {
  display: none;
  }
}

.info-container {
  display: flex;
  // width: 50%;
  flex-direction: column;
  justify-content: space-between;

  @include onTablet {
    width: 50%;

    .olP {
      margin-block-start: 0;
      margin-block-end: 0;
      padding-inline-start: 30px;
    }
  }

  // @include
}

// .cooperation-foto {
//   width: 100%;

// }

.cooperation-img {
  background-image: url(../Photo/cooperation3.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;

  display: none;
  width: 50%;

  @include onTablet {
    display: flex;
  }
 }
