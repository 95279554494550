html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
address,
em,
img,
b,
// ol,
ul,
li,
form,
label,
legend,
footer,
header,
textarea,
menu,
nav {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
}
