.button-container {
  display: flex;
}

.button {
  background: #000000;
  width: 240px;
  height: 50px;
  transition: all 0.5s;
}

.button:hover {
  background: #373432;
}

.header--button {
  position: absolute;
  bottom: 15px;
  right: 15px;


  @include onTablet {
    bottom: 20px;
    right: 35px;
  }

  @include onDesktop {
    right: 70px;
  }
}

.button-link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
