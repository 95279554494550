h1 {
  font-size: 24px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  line-height: 35px;
  font-weight: 800;

  @include onTablet {
    font-size: 36px;
    line-height: 40px;
  }
}

.title__white {
  color: white;
  font-weight: 500;
}

.button-text {
  letter-spacing: 0.10em;
  color: white;
  font-size: 15px;
  text-transform: uppercase;
}

.button-text-black {
  font-family: 'Inter', sans-serif;
  letter-spacing: 0.10em;
  color: black;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 800;
}

h2 {
  font-size: 20px;
  font-weight: 600;

  @include onTablet {
    font-size: 28px;
  }
}

h3 {
  font-size: 16px;
}

p {
  font-size: 14px;

  @include onTablet {
    font-size: 16px;
  }

  @include onDesktop {
    font-size: 18px;
  }
}

li {
  font-size: 14px;
  padding-bottom: 10px;
  text-align: start;

  @include onTablet {
    font-size: 16px;
  }

  @include onDesktop {
    font-size: 18px;
    padding-bottom: 15px;
  }
}

.onDesktop {
  display: none;

  @include onDesktop {
    display: block;
  }
}

.onTablet {
  display: none;

  @include onTablet {
    display: block;
  }
}

.onTablet-NonDesktop {
  display: none;

  @include onTablet {
    display: block;
  }

  @include onDesktop {
    display: none;
  }
}

.onMobile {
  display: block;

  @include onTablet {
    display: none;
  }
}

.normal {
  font-weight: 200;
}

.italic {
  font-style: italic;
}

.italic-bold {
  font-style: italic;
  font-weight: 500;
}


