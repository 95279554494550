.design-prices {
  background-color: white;
}

.prices__inners-box {
  @include onDesktop {
    display: flex;
    padding: 0 70px 0 70px;
    gap: 30px;

    .section {
      padding: 0;
    }

    .prices__inners {
      width: 100%;
      height: 500px;
    }
  }
}

.prices__inner {
  box-sizing: border-box;
  display: flex;

  @include onTablet {
    height: 300px;
  }

  @include onDesktop {
    height: 500px;
  }
}

.prices__lside {
  width: 48%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right 0 bottom 0;
  position: relative;
  z-index: 0;

  @include onTablet {
    width: 50%;
  }
}

.prices__lside::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7); /* Затемненный цвет: черный с прозрачностью 50% */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.lside-container {
  width: 100%;
  height: 100%;
}

.button-box {
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  z-index: 3;
  bottom: 15px;

  .b-size {
    width: 140px;
    height: 36px;
    box-sizing: border-box;
    border: solid 1px white;

    @include onTablet {
      width: 240px;
      height: 50px;
    }

    .button-link {
      display: flex;
      align-items: center;
    }

    .b-text {
      color: white;
    }
  }
}

.prices__rside {
  box-sizing: border-box;
  padding-left: 1px;
  width: 52%;
  background-color: #F4F3F3;
  border-top: solid 1px black;
  border-right: solid 1px black;
  border-bottom: solid 1px black;

  @include onTablet {
    padding-left: 10px;
    width: 50%;
  }

  @include onDesktop {
    padding-left: 5px;
  }
}

.rside-title {
  padding: 10px 10px 10px 17px;

  @include onDesktop {
    padding-left: 25px;
  }
}

.prices__type {
  background-color: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(5px);
  display: block;
  width: 100%;
  position: absolute;
  z-index: 2;
}

.lside-title {
  padding: 10px 15px;
  color: white;

  @include onTablet {
    text-align: center;
  }
}

.prices__sum {
  display: flex;

  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;

  .meter {
    color:rgb(197, 179, 177);
  }

  .price {
    color: white;
    padding: 10px 5px;

    @include onTablet {
      font-size: 40px;
    }

    @include onDesktop {
      font-size: 60px;
    }
  }
}

ul {
  list-style-type: none;
}

.check, .uncheck {
  position: relative;
  padding-left: 17px; /* Отступ слева для размещения изображения маркера */

  @include onTablet {
    padding-left: 25px;
  }
}

.check::before {
  content: "";
  position: absolute;
  left: 0;
  // top: 20%; /* Размещаем по вертикали по центру */
  // transform: translateY(-5%); /* Выравниваем по вертикали по центру */
  width: 16px; /* Ширина изображения маркера */
  height: 16px; /* Высота изображения маркера */
  background-image: url("../Photo/Galochka.svg"); /* Путь к изображению маркера */
  // background-image: url("./Photo/check.svg");
  background-size: cover; /* Растягиваем изображение маркера    #C5B3B1*/

  @include onTablet {
    transform: translateY(-25%);
    width: 25px;
    height: 25px;
  }

  @include onDesktop {
    transform: translateY(-10%);
  }
}

.uncheck {
  color: #C5B3B1;
}

.uncheck::before {
  content: "";
  position: absolute;
  left: 0;

  transform: translateY(10%);
  width: 15px; /* Ширина изображения маркера */
  height: 15px; /* Высота изображения маркера */
  background-image: url("../Photo/closeIcon.svg");
  background-size: cover;

  @include onTablet {
    transform: translateY(20%);
    left: 2px;
  }

  @include onDesktop {
    left: 0;
    width: 20px;
    height: 20px;
  }
}







